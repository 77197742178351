@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"



















































.home-page

  background-color: $white

  .links
    margin-top: 1rem
    margin-bottom: 1rem

    .calc-link
      display: block
      padding: 1rem .5rem
      margin-bottom: 1rem
      background-color: $white-ter
      text-align: center
      line-height: 0

      .svg-icon
        width: 68%
        height: auto
        margin: 2% 0
        fill: $primary

      &:hover
        background-color: $link-hover

        .svg-icon
          fill: $white

    p
      width: 90%
      line-height: 1.3em
      margin-bottom: .8rem

  .footnote

    p
      line-height: 1.3em

  .font-grey
    color: $text-light

+tablet
  .home-page
    .links
      .calc-link
        padding: 1rem 1rem
