@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"






































.result-value
  position: relative
  padding: .5rem 0 .5rem 2.8rem

  .svg-icon
    position: absolute
    left: 0
    top: 1.6rem

  .fieldlabel
    height: 1.8rem
    margin-left: -.3rem
    font-size: $size-6
    font-family: $family-mono-light
    text-transform: uppercase

  .value
    font-size: $size-1
    color: $info
    line-height: 1em
    font-family: $family-mono-light

  .unit
    color: $info

+tablet

  .result-value
    padding: 0 0 0 2.5rem

    .svg-icon
      top: .7rem

    .fieldlabel
      height: 1.2rem
      font-size: $size-7

    .value
      font-size: $size-3

    &.result-wa1,
    &.result-wa2,
    &.result-wa3

      .unit
        display: none

+desktop

  .result-value
    padding: 0 0 0 2.8rem

    .value
      font-size: $size-2

    &.result-wa1,
    &.result-wa2,
    &.result-wa3

      .unit
        display: inline
