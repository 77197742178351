@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"





































.svg-icon
  height: 2.4rem
  width: 2.4rem
  fill-rule: evenodd
  clip-rule: evenodd
  stroke-linejoin: round
  stroke-miterlimit: 1.41421
  fill: $black

  &.is-primary
    fill: $primary

  &.is-white
    fill: $white

  &.is-danger
    fill: $red

  &.is-success
    fill: $green
