@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"





























.footer-segment
  padding-top: 1rem
  padding-bottom: 1rem
  background-color: $black
  z-index: $z-index-content

  .copyright
    color: $grey-light
    font-family: $family-mono-light
    font-size: $size-6

  .disclaimer
    text-align: left
    color: $grey-light

  .imprint
    text-align: left
    font-family: $family-mono
    a
      color: $white
      &:hover
        color: $white

  +tablet
    .disclaimer
      text-align: center

    .imprint
      text-align: right
