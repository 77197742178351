@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"



















































.overlay-segment
  position: fixed
  display: none
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: $black
  opacity: 0

  &.is-visible
    display: block
    opacity: .5
    z-index: $z-index-overlay-menu

  &.is-dialog
    background-color: $white
    z-index: $z-index-overlay-dialog

    &.is-visible
      opacity: 1
