@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"























































































.lang-select-elem

  .dropdown-trigger
    display: none

  .dropdown-menu
    position: relative
    display: block
    top: auto
    min-width: auto

    .dropdown-content
      padding: 0
      box-shadow: none

      .dropdown-item
        display: inline-block
        padding: 1rem 1.5rem 1rem 0
        font-size: $size-4
        text-align: left
        color: $primary
        cursor: pointer

        &.is-selected
          color: $grey-dark
          cursor: default

+tablet

  .lang-select-elem
    display: block

    .dropdown-trigger
      display: block

      .button
        border: none
        padding: .5em .75em
        height: auto
        text-transform: uppercase

        &.is-text
          text-decoration: none
          color: $white

          &:hover,
          &.is-hovered,
          &:focus,
          &.is-focused
            color: $white
            background-color: transparent

        .icon
          margin: -.2rem 0 0 .4rem !important

          .svg-icon
            width: 1rem !important
            height: 1rem !important
            fill: $white

    .dropdown-menu
      position: absolute
      display: none
      right: 0
      top: 100%
      min-width: 4rem
      box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1)

      .dropdown-content
        padding: 0
        box-shadow: none

        .dropdown-item
          display: block
          font-size: $size-6
          padding: 0.375rem 1rem
          text-align: center
          color: #4a4a4a
          cursor: pointer

          &.is-selected
            color: $primary
            cursor: default

