@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"












































































































.radio-input

  .control-frame
    padding: 2px
    background-color: $white
    border: 1px solid $border

    .control
      position: relative
      display: flex
      background-color: $white
      height: 2rem

      .handle
        position: absolute
        display: block
        flex: none
        height: 100%
        background-color: $primary
        transition: left .4s ease

      label
        position: relative
        display: block
        flex-basis: 0
        flex-grow: 1
        flex-shrink: 1
        margin: 0
        text-align: center
        line-height: 2rem
        font-size: $size-5
        font-family: $family-mono-light
        color: $primary

        input
          position: absolute
          opacity: 0
          filter: alpha(opacity=0)
          visibility: visible

        &.active
          color: $white
          font-size: $size-4
          transition: all .4s ease

+tablet

  .radio-input

    .control-frame

      .control
        height: 1.5rem

        label
          font-size: $size-7
          line-height: 1.5rem

          &.active
            font-size: $size-6

